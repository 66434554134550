import './selecionarPrecificador.css';
import { useState,useEffect,useContext} from 'react';
import {IoClose} from 'react-icons/io5';
import {AuthContext} from '../../contexts/auth';
import api from '../../services/api';
import {toast} from 'react-toastify';
import InputPesquisa from '../InputPesquisa';


export default function SelecionarPrecificador({id,formato,fechar}){
    console.log(formato)
    //CATEGORIA
    const [categoriaSelecionada,setCategoriaSelecionada] = useState('0');
    const [categoria,setCategoria] = useState([]);

    //Lista de produtos
    const [listaP,setListaP] = useState([])
    const [listaPFiltrada, setListaPFiltrada] = useState([]);
    
    //ITENS SELECIONADOS
    const [selecionados,setSelecionados] = useState([]);
    const handleSelect = function(selectedItems) {
        const select = [];
        for (let i=0; i<selectedItems.length; i++) {
            select.push(selectedItems[i].value);
        }
        setSelecionados(select);
    }

    //ITENS DO PEDIDO
    const [produtosP,setProdutosP] = useState('');

    const [precoNovo,setPrecoNovo] = useState('')
    const [precoNovoPromocional, setPrecoNovoPromocional] = useState("")

    //Numero de paginas
    const [paginas,setPaginas] = useState(0);

    //Loja
    const [jLoja,setJloja] = useState();

    const [load,setLoad] = useState(false);
    const {user,signOut} = useContext(AuthContext);

    useEffect(()=>{
        setJloja(JSON.parse(localStorage.getItem('dataLoja')))
        //BUSCA CATEGORIAS
        async function getCategorias(){
            setLoad(true)
            await api.get('/categorias',
                {
                    headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                let json = resp.data
                setCategoria(json.categorias.map((v,k)=>{
                    return <option key={k} value={v.id}>{v.categoria}</option>
                }))
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoad(false)
                }
            })
        }
        //BUSCA PRODUTOS DO VAREJISTA
        async function getProdutos(){
            setLoad(true)
            let dataLoja = localStorage.getItem('dataLoja')
            const loja = JSON.parse(dataLoja);
            await api.get('/produtos/varejo',
                    {
                        headers:{'Authorization':user.token},
                        params:{
                            varejo:loja.varejo,
                            preco:loja.preco
                        }
                    },
            ).then((resp)=>{
                setListaP(resp.data.produtos)
                setListaPFiltrada(resp.data.produtos)
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    setListaP([])
                    setListaPFiltrada([])
                    toast.error(error.response.data.error)
                    setLoad(false)
                }
            })
        }
        getProdutos()
        getCategorias()
    },[user.token,signOut])

    //CRIA PDF
    useEffect(()=>{
    async function criaPDF(){
        if(produtosP !== ''){
        const itensPedido = []
        for(let i=0;i<produtosP.length;i++){
            if(produtosP[i].preco > 0){
                itensPedido.push({descricao:produtosP[i].descricao,descricao2:produtosP[i].descricao2,preco:String(produtosP[i].preco),preco_promocional:String(produtosP[i].preco_promocional),peso:produtosP[i].peso})
            }
            else{ 
                toast.info('CONTÉM ITENS SEM PREÇO PARA A SUA LOJA.')
            }
        }
        await api.post('/pdf/precificador',
            {
                listaProdutos:itensPedido,
                pedido_id:id,
                formato:formato,
                loja:jLoja.nome,
                nome:jLoja.liderSenior,
                contato:jLoja.telefoneS,
                endereco:jLoja.endereco,
                bairro:jLoja.bairro,
                cep:jLoja.cep,
                cidade:jLoja.cidade,
                estado:jLoja.estado,
            },
            {
                headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            setLoad(false)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setLoad(false)
            }
        })
        }
    }
    criaPDF()
    },[produtosP,id,signOut,user.token])

    //BUSCA PRODUTOS CONFORME MUDA CATEGORIA
    useEffect(()=>{
        async function getItens(){
                if(categoriaSelecionada !== '0'){
                setLoad(true)
                await api.get(`/produtos/categoria/${categoriaSelecionada}`,
                    {
                        headers:{'Authorization':user.token},
                        params:{
                            varejo:jLoja.varejo,
                            preco:jLoja.preco
                        }
                    }
                ).then((resp)=>{
                    setListaP(resp.data.produtos)
                    setListaPFiltrada(resp.data.produtos)
                    setLoad(false)
                }).catch((error)=>{
                    if(error.response.status === 401){
                        toast.info(error.response.data.error)
                        signOut()
                    }else{
                        setListaP([])
                        setListaPFiltrada([])
                        toast.error(error.response.data.error)
                        setLoad(false)
                    }
                })
            }else{
                setLoad(true)
                await api.get('/produtos/varejo',
                        {
                            headers:{'Authorization':user.token},
                            params:{
                                varejo:jLoja.varejo,
                                preco:jLoja.preco
                            }
                        },
                ).then((resp)=>{
                    setListaP(resp.data.produtos)
                    setListaPFiltrada(resp.data.produtos)
                    setLoad(false)
                }).catch((error)=>{
                    if(error.response.status === 401){
                        toast.info(error.response.data.error)
                        signOut()
                    }else{
                        setListaP([])
                        setListaPFiltrada([])
                        toast.error(error.response.data.error)
                        setLoad(false)
                    }
                })
            }
        }
        getItens()
    },[categoriaSelecionada,signOut,user.token])

    //ADICIONA iTEM AO PEDIDO
    async function adicionaItem(){
        if(selecionados !== '0'){
            setLoad(true)
            let itens = []
            for(let i=0;i < selecionados.length;i++){
                let filtroProduto = listaP.filter(produto => produto.id == selecionados[i])
                itens.push({id_produto:selecionados[i],precoOficial:filtroProduto[0].preco,id_pedido:id})
            }
            //Adiciona itens
            await api.post('/produtos_pedido',
                {
                    relacionamentos:itens
                },
                {
                    headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                toast.success(resp.data.mensagem)
                setSelecionados(['0'])
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setLoad(false)
                }
            })
            //busca itens do pedido
            await api.get(`/produtos_pedido/${id}`,
                {
                    headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                setProdutosP(resp.data.produtos)
                setPaginas(resp.data.quantidade)
                setLoad(false)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setProdutosP('')
                    setLoad(false)
                }
            })
        }else{
            toast.info('Nenhum item selecionado')
        }
        setLoad(false)   
    }

    //altera preco executado
    async function alteraPrecoExecutado(itemId,itemPreco,precoPromocional){
            setLoad(true)
            await api.patch(`/produtos_pedido/${itemId}`,
                {
                    preco:itemPreco,
                    preco_promocional: precoPromocional
                },
                {
                  headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                toast.success(resp.data.mensagem)
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setLoad(false)
                }
            })
            //busca itens do pedido
            await api.get(`/produtos_pedido/${id}`,
                {
                    headers:{'Authorization':user.token}
                }
            )
            .then((resp)=>{
                if(resp.data.produtos.length === 0){
                    setProdutosP('')
                    setPaginas(resp.data.quantidade)
                    setLoad(false)
                }else{
                    setProdutosP(resp.data.produtos)
                    setPrecoNovo('')
                    setPrecoNovoPromocional('')
                    setPaginas(resp.data.quantidade)
                    setLoad(false)
                }
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.info(error.response.data.error)
                    setProdutosP('')
                    setLoad(false)
                }
            })
            setLoad(false)
    }
    //Remove item do pedido
    async function removeItem(itemId){
        setLoad(true)
        await api.delete('/produtos_pedido',
            {
            params:
            {
                produto_id:itemId
            },
            headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setLoad(false)
            }
        })
        //busca itens do pedido
        await api.get(`/produtos_pedido/${id}`,
            {
                headers:{'Authorization':user.token}
            }
        )
        .then((resp)=>{
            if(resp.data.produtos.length === 0){
                setProdutosP('')
                setPaginas(resp.data.quantidade)
                setLoad(false)
            }else{
                setProdutosP(resp.data.produtos)
                setPaginas(resp.data.quantidade)
                setLoad(false)
            }
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setProdutosP('')
                setLoad(false)
            }
        })
        setLoad(false)
    }

    if(load === true){
        return(
            <div className='modalPrecificador'>
                <div className='modalPrecificador-container'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }
    function filtroDeProdutos(lista, valor){
        return lista.filter((produto) => produto.produto.toUpperCase().includes(valor.toUpperCase()));
    }
    console.log(formato)

    return(
        <div className='modalPrecificador'>
            <div className='modalPrecificador-fechar'>
                <button onClick={fechar} className='modalPrecificador-btn-fechar'>
                    <IoClose size={30} color='#FFF'/>
                </button>
            </div>
            <div className='modalPrecificador-container'>
                <div>
                    <h1>Adicionar Itens</h1>
                    <div className='modalPrecificador-componentes'>
                        <div className='adicionar-itens-header'>
                            <div className='pesquisar-container'>
                                <h3>Pesquisar</h3>
                                <div className='input-pesquisa-container'>
                                    <InputPesquisa lista={listaP} setLista={setListaPFiltrada} funcaoDeFiltro={filtroDeProdutos}/>
                                </div>
                            </div>
                            <div className='sel-categoria-container'>
                                <h3>Categoria</h3>
                                <select className='sel-categoria' value={categoriaSelecionada} multiple={false} onChange={(e)=>{setCategoriaSelecionada(e.target.value)}}>
                                    <option key={0} value={0}>SELECIONAR</option>
                                    {categoria}
                                </select>
                            </div>
                        </div>
                        <div className='sel-produto-container'>
                            <h3>Selecione os Produtos</h3>
                            <select className='sel-produto' value={selecionados} size='5' multiple={true} onChange={(e)=>{handleSelect(e.target.selectedOptions)}}>
                                {listaPFiltrada.map((produto)=>{
                                    return <option key={produto.id} value={produto.id}>{produto.produto}</option>
                                })}
                            </select>
                        </div>
                        <div className='modalPrecificador-button'>
                            <button onClick={adicionaItem}>ADICIONAR</button>
                            <button onClick={()=>setSelecionados(0)}>LIMPAR</button>
                        </div>
                        <div className='modalPrecificador-button1'>
                            <a target='_blank' rel='noreferrer' href={`https://apiswifttrade.ifollow.com.br/uploads/ID${id}.pdf`}>DOWNLOAD PDF</a>
                        </div>
                    </div>
                </div>
                <div className='modalPrecificador-preview'>
                    <h1>Itens Selecionados</h1>
                    {produtosP !== '' ? (
                        <div className='modalPrecificador-preview-itens'>
                            {produtosP.map((item)=>{
                                if(item.preco > 0){
                                    item.preco = Number(item.preco).toFixed(2)
                                    item.preco_promocional = Number(item.preco_promocional).toFixed(2)
                                    return(
                                        <div className='modalPrecificador-preview-container'
                                        key={item.id}>
                                            <div className='modalPrecificador-preview-areaItem'>
                                                <h1>{item.descricao}</h1>
                                                <h2>{item.descricao2}</h2>
                                                <div className={`item-baixo ${formato == 4 || formato == 5 ? " diminuir" : ""}`}>
                                                    {formato == 4 || formato == 5 ? <h2>De:</h2> : <></>}
                                                    <h3 >{item.preco.toString().slice(0,-3)},</h3>
                                                    <h4>{item.preco.toString().slice(-2)}</h4>
                                                    {formato == 4 || formato == 5 ? <h2>Por:</h2> : <></>}
                                                    {formato == 4 || formato == 5 ? <h3>{item.preco_promocional}</h3> : <></>}
                                                </div>
                                                <div className={`textPeso ${formato == 4 || formato == 5 ? " right" : ""}`}>
                                                    <h2>{item.peso}</h2>
                                                </div>
                                            </div>
                                                <div className='alteraPreco'>
                                                    <h3>ALTERAR PREÇO</h3>
                                                    {formato == 5 || formato == 4 ? <h5 className='label'>De:</h5> : <></>}
                                                    <input className='inputAlterarPreco' type={'number'} placeholder={'Ex: 89,99'} value={precoNovo}  onChange={(e) => setPrecoNovo(e.target.value)}/>
                                                    {formato == 5 || formato == 4 ? <h5 className='label'>Por:</h5> : <></>}
                                                    {formato == 5 || formato == 4 ? <input className='inputAlterarPreco' type={'number'} placeholder={'Ex: 89,99'} value={precoNovoPromocional}  onChange={(e) => setPrecoNovoPromocional(e.target.value)}/> : <></>}
                                                </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <button className='modalPrecificador-preview-container-btn' onClick={()=>alteraPrecoExecutado(item.id,precoNovo,precoNovoPromocional)}>SALVAR</button>
                                                <button className='modalPrecificador-preview-container-btn' onClick={()=>removeItem(item.id)}>REMOVER</button>
                                            </div>
                                        </div>
                                    )
                                }
                                else{ 
                                    return(
                                        <div className='modalPrecificador-preview-container'>
                                            <div key={item.id} className='modalPrecificador-preview-areaItem'>
                                                <h1>{item.descricao}</h1>
                                                <h2>{item.descricao2}</h2>
                                                <div className='item-baixo'>
                                                    <h3>SEM PREÇO</h3>
                                                </div>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <button className='modalPrecificador-preview-container-btn' onClick={()=>removeItem(item.id)}>REMOVER</button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                    ) : <div className='container-vazio'>
                            <h1>Nenhum Item Adicionado</h1>
                        </div> 
                }
                </div>
            </div>
        </div>
    )
}